// @flow

import { PageContent } from "@components";
import { LocalizedPreview } from "@containers";
import { Router } from "@reach/router";
import maps from "@utils/preview/maps/generic";
import { graphql } from "gatsby";
import React from "react";

/**
 * Preview body, could possibly be more generalized
 */
const Body = ({ entryId, token, environment }: any) => (
    <LocalizedPreview
        id="article-preview"
        entryId={entryId}
        token={token}
        environment={environment}
        maps={maps}
    >
        {(data, _, selectLocale) => (
            <PageContent
                title={data.title}
                entry={data}
                publishedMarkets={data.markets}
                slug={data.slug}
                enableScrollTop={data.enableScrollTop}
                previewLocale={selectLocale}
            />
        )}
    </LocalizedPreview>
);

const ArticlePreview = ({ data }: *) => (
    <Router>
        <Body
            path="/preview/article/:entryId/:token"
            environment={data.site.siteMetadata.environment}
        />
    </Router>
);

export default ArticlePreview;

export const query = graphql`
    {
        site {
            siteMetadata {
                environment
            }
        }
    }
`;
